<template>
  <ion-page>
    <page-header title="Angebot" />
    <ion-content :fullscreen="true">
      <ion-grid>
        <ion-row>
          <ion-col size="12" size-lg="6" offset="0" offset-lg="3">

            <ion-item v-for="i in 10" :key="i" lines="none">
              <ion-thumbnail slot="start">
                <img src="https://via.placeholder.com/100">
              </ion-thumbnail>
              <ion-label>
                <h3>Item Thumbnail {{ i }}</h3>
                <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>
                <p>Adipisci facilis eius labore enim animi laudantium</p>
              </ion-label>
            </ion-item>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonPage, IonContent, IonItem, IonThumbnail, IonLabel, IonGrid, IonCol, IonRow } from '@ionic/vue';
import PageHeader from '@/components/PageHeader.vue'

export default  {
  name: 'TabItems',
  components: { IonContent, IonPage, IonItem, IonThumbnail, IonLabel, IonGrid, IonCol, IonRow, PageHeader }
}
</script>
